import { Container } from "react-bootstrap";
import Anmelden from "./anmelden";

function AditionalBlock({ aditional, newform = true }) {
  return (
    <section className="layout layout__aditional">
      <Container fluid>
        <div className="aditionalCard">
          <h3 data-aos="fade-up" className="layout_subtitle mb-4 aos-init">
            {aditional.title}
          </h3>
          <div className="d-flex justify-content-between gap-5 align-items-start flex-column flex-lg-row">
            <p
              data-aos="fade-up"
              className="layout_text aos-init max_975"
              dangerouslySetInnerHTML={{ __html: aditional.info }}
            />

            <div data-aos="fade-up" className="flex-shrink-0 aos-init">
              <Anmelden
                btn="btn btn__fill btn__fill_primary layout_mt_min mt-0 "
                btnName={aditional.button}
                newform={newform}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default AditionalBlock;
