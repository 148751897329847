import gql from "graphql-tag";

const AUSBILDUNG_QUERY = gql`
  query Ausbildung {
		education {
			data {
				attributes {
					slug
					title
					description
					thumbnail{
						data{
							attributes{
								url
							}
						}
					}
					seo{
						metaTitle
						metaDescription
						metaImage{
							data{
								attributes{
								url
								}
							}
						}
						metaSocial{
							title
						}
						keywords
						metaRobots
						metaViewport
						canonicalURL
					}
					course{
						title
						description
						courses{
							title
							description
						}
					}
					advantages{
						title
						advantageslist{
							title
							description
						}
						thumb{
							data{
								attributes{
									url
								}
							}
						}
					}
					faq{
						title
						qa{
							id
							question
							answer
						}
					}
				}
			}
		}
  }
`;

export default AUSBILDUNG_QUERY;