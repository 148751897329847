import Query from "../../components/Query";
import HOME_QUERY from "../../queries/home";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const TeamBlock = () => {
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <section className="layout layout__bg layout__bg_primary">
            <Container fluid>
              <h2
                className="layout_title layout_title__light d-xl-none"
                data-aos="fade-up"
              >
                {home.data.attributes.Team.title}
              </h2>
              <Row className="align-items-center">
                <Col
                  xs={12}
                  md={6}
                  xl={5}
                  xxl={6}
                  className="text-end text-md-start text-xxl-end mt-4 mt-md-0"
                >
                  <img
                    data-aos="fade-up"
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      home.data.attributes.Team.thumbnail.data.attributes.url
                    }
                    alt={home.data.attributes.Team.title}
                    className="autoImg layout_image layout_image__round"
                  />
                </Col>
                <Col xs={12} md={10} xl={5} className="offset-xl-1">
                  <h2
                    data-aos="fade-up"
                    className="layout_title layout_title__light d-none d-xl-block layout_mb"
                  >
                    {home.data.attributes.Team.title}
                  </h2>
                  <h3
                    data-aos="fade-up"
                    className="layout_subtitle layout_subtitle__500 mb-4"
                  >
                    {home.data.attributes.Team.subtitle}
                  </h3>
                  <p
                    data-aos="fade-up"
                    className="layout_text pe-md-2 pe-xl-3 pe-xxl-5 layout_mb"
                    dangerouslySetInnerHTML={{
                      __html: home.data.attributes.Team.description,
                    }}
                  />
                  <Link
                    to="/team"
                    target="_top"
                    className="btn btn__fill btn__fill_primary"
                    data-aos="fade-up"
                  >
                    ÜBER UNS
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default TeamBlock;
