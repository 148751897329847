import gql from "graphql-tag";

const POLICY_QUERY = gql`
query Policy {
    policy{
        data{
            attributes{
                title
                slug
                content
            }
        }
    }
}
`;

export default POLICY_QUERY;