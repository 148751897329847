import gql from "graphql-tag";

const PUSH_QUERY = gql`
query Push {
    push{
        data{
            attributes{
                title
                description
                Thumb{
                    data{
                          attributes{
                            url
                          }
                        }
                }
                buttontext
                enabled
            }
        }
    }
}
`;

export default PUSH_QUERY;