import React, { useEffect } from "react";

function Success(props) {
  useEffect(() => {
    const timeoutId = setTimeout(props.hideModal, 4000);

    window.gtag("event", "conversion", {
      send_to: "AW-11286585168/dwqMCMfNhs0YENC-7oUq",
    });

    return () => clearTimeout(timeoutId);
  }, [props.hideModal]);

  return (
    <div className="text-center">
      <h3 className="h2 layout_mb__30">VIELEN DANK FÜR DEINE ANMELDUNG!</h3>
      <div className="layout_iframe layout_iframe__small layout_mb__30">
        <img className="relative" src={"/new/success.webp"} alt="success img" />
      </div>
      <p className="layout_text layout_mb">
        Vielen Dank für Deine Voranmeldung.
        <br />
        Wir melden uns bald bei Dir ;)
      </p>
      <h4>Bis bald!</h4>
    </div>
  );
}

export default Success;
