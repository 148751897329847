import { Col, Container, Row } from "react-bootstrap";

const OffersBlock = ({ offers }) => {
  return (
    <section className="layout layout__bg layout__bg_primary layout_mt">
      <Container fluid>
        <Row className="flex-column gap-offer">
          {offers.map((item, index) => (
            <Col key={index}>
              <Row className="d-flex gap-5 gap-lg-0 align-items-lg-center flex-column flex-lg-row">
                <Col
                  xs={12}
                  lg={5}
                  data-aos="fade-up"
                  className="flex-shrink-0 aos-init max_500"
                >
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      item.img.data.attributes.url
                    }
                    alt={item.title}
                    className="autoImg layout_image layout_image__offer layout_image__round mb-0"
                  />
                </Col>
                <Col
                  xs={12}
                  md={10}
                  lg={7}
                  className="flex justify-content-between"
                >
                  <div className="max_975">
                    <h3
                      data-aos="fade-up"
                      className="layout_subtitle mb-4 aos-init"
                    >
                      {item.title}
                    </h3>
                    <p
                      data-aos="fade-up"
                      className="layout_text aos-init "
                      dangerouslySetInnerHTML={{ __html: item.info }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OffersBlock;
