import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);
const link = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`
});
const client = new ApolloClient({
  ssrMode: true,
  cache,
  link,
  ssrForceFetchDelay: 100
});

export default client;