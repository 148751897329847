import gql from "graphql-tag";

const HOME_QUERY = gql`
  query Home {
    home {
      data {
        attributes {
          seo {
            metaTitle
            metaDescription
            metaImage {
              data {
                attributes {
                  url
                }
              }
            }
            metaSocial {
              title
            }
            keywords
            metaRobots
            metaViewport
            canonicalURL
          }
          headerblock {
            id
            price
            title
            info
            media {
              data {
                attributes {
                  url
                  alternativeText
                  ext
                }
              }
            }
          }
          Intensivecourse {
            title
            title2
            description
            Schedule {
              id
              day
              date
              time
            }
          }
          referal {
            title
            descr
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
          Services {
            title
            description
            Categories {
              id
              name
              description
            }
          }
          Team {
            title
            subtitle
            description

            thumbnail {
              data {
                attributes {
                  url
                }
              }
            }
          }
          FAQ {
            title
            qa {
              id
              question
              answer
            }
          }
        }
      }
    }
  }
`;

export default HOME_QUERY;
