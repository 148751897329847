import gql from "graphql-tag";

const REVIEW_QUERY = gql`
query Review {
	review{
		data{
			attributes{
				title
				glink
				clickclicklink
				review{
					name
					description
					scores
					type
				}
			} 
		}
	}
}
`;

export default REVIEW_QUERY;