import gql from "graphql-tag";

const IMPRESSUM_QUERY = gql`
query Impressum {
    imprint{
        data{
            attributes{
                title
                slug
                content
            }
        }
    }
}
`;

export default IMPRESSUM_QUERY;