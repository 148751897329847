import { Col, Container, Row } from "react-bootstrap";

const ReferalBlock = ({ title, desc, img }) => {
  return (
    <section className="layout layout__color_primary pb-0 pb-md-4">
      <Container fluid>
        <Row className="align-items-center">
          <Col
            xs={12}
            md={6}
            xl={6}
            xxl={5}
            className="text-end text-md-start text-xxl-end mt-4 mt-md-0 order-xl-1 offset-xl-1"
          >
            <img
              data-aos="fade-up"
              src={img?.url ? process.env.REACT_APP_BACKEND_URL + img.url : img}
              alt={title || "Referal"}
              className="autoImg layout_image layout_image__round"
            />
          </Col>
          <Col xs={12} md={10} xl={5} className="">
            <h3
              data-aos="fade-up"
              className="layout_subtitle layout_title mb-4"
            >
              {title}
            </h3>
            <p
              data-aos="fade-up"
              className="layout_text pe-md-2 pe-xl-3 pe-xxl-5 layout_mb"
              dangerouslySetInnerHTML={{
                __html: desc,
              }}
            ></p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReferalBlock;
