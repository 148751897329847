import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function InfoBlock(props) {
    return(
        <Container fluid>
            <Row>
                <Col className='layout_mb'>
                    <h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: props.bf.title}}></h2>
                </Col>
                <Col xs={12} sm={11} lg={8} xl={5} xxl={5}>
                    <p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: props.bf.description}}></p>
                </Col>
            </Row>
        </Container>
    )
}

export default InfoBlock;