import gql from "graphql-tag";

const CATEGORY_ARTICLES_QUERY = gql`
  query Category($slug: String!) {
    categories(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          slug
          title
          seo {
            metaTitle
            metaDescription
            metaImage {
              data {
                attributes {
                  url
                }
              }
            }
            metaSocial {
              title
            }
            keywords
            metaRobots
            metaViewport
            canonicalURL
          }
          header {
            title
            categoryname
            description
            thumb {
              data {
                attributes {
                  url
                }
              }
            }
          }
          promo {
            id
            title
            info
            button
          }
          catinfo {
            title
            description
            description1
            description2
            thumb {
              data {
                attributes {
                  url
                }
              }
            }
            info {
              id
              title
              description
              thumb {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
            list {
              id
              description
            }
          }
          infoblock {
            title
            description
          }
          faq {
            title
            qa {
              id
              question
              answer
            }
          }
        }
      }
    }
  }
`;

export default CATEGORY_ARTICLES_QUERY;
