import React, { useEffect, useState, useRef } from "react";

export function WrapText({ text }) {
  const [lines, setLines] = useState([]);
  const textContainerRef = useRef(null);

  useEffect(() => {
    const words = text.split(" ");

    const createLines = () => {
      if (!textContainerRef.current) return;

      const containerWidth = textContainerRef.current.clientWidth;
      const newLines = [];
      let currentLine = "";

      const testLineWidth = (line) => {
        const span = document.createElement("span");
        span.innerText = line;
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";
        textContainerRef.current.appendChild(span);
        const width = span.offsetWidth;
        textContainerRef.current.removeChild(span);
        return width;
      };

      words.forEach((word) => {
        const testLine = currentLine + word + " ";
        if (testLineWidth(testLine) > containerWidth) {
          newLines.push(currentLine.trim());
          currentLine = word + " ";
        } else {
          currentLine = testLine;
        }
      });

      if (currentLine) {
        newLines.push(currentLine.trim());
      }

      setLines(newLines);
    };

    createLines();

    window.addEventListener("resize", createLines);
    return () => {
      window.removeEventListener("resize", createLines);
    };
  }, [text]);

  return (
    <div ref={textContainerRef}>
      {lines.map((line, index) => (
        <p className={lines.length === 1 ? "rounded" : ""} key={index}>
          {line}
        </p>
      ))}
    </div>
  );
}
