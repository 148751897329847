import { Container } from "react-bootstrap";
import HOME_QUERY from "../../queries/home";
import Query from "../Query";
import FAQ from "../Faq";

const FAQBlock = () => {
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <section className="layout layout__color_primary">
            <Container fluid>
              <h2 className="" data-aos="fade-up">
                FAQ
              </h2>
              <h3 className="" data-aos="fade-up">
                {home.data.attributes.FAQ.title}
              </h3>
              <FAQ elements={home.data.attributes.FAQ.qa}></FAQ>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default FAQBlock;
