import { Container } from "react-bootstrap";
import UP_QUERY from "../../queries/home/up";
import Query from "../Query";

function UpNav() {
  return (
    <Query query={UP_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <div className="layout layout__bg_primary upNav">
            <Container fluid>
              <h6 className="text-center upNav_text">
                {home.data.attributes.angebotzeile}
              </h6>
            </Container>
          </div>
        );
      }}
    </Query>
  );
}

export default UpNav;
