import gql from "graphql-tag";

const UP_QUERY = gql`
  query Home {
    home {
      data {
        attributes {
          angebotzeile
        }
      }
    }
  }
`;

export default UP_QUERY;
