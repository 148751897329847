import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Splide, SplideSlide } from '@splidejs/react-splide';

function Team(props) {
    return(
        <section className="layout layout__color_primary pb-0">
            <Container fluid>
                <Row>
                    <Col lg={3} className="layout_mb__30">
                        <h2 data-aos="fade-up">Team</h2>
                    </Col>
                    <Col>
                        <Splide className='splide_mr' aria-label="My Favorite Images"
                            options={
                                {
                                    gap: '40px',
                                    arrows: false,
                                    pagination: false,
                                }
                            }
                        >
                            {props.teams.person.map((item, index) => {
                                return(
                                    <SplideSlide key={`team_${index}`}>
                                        <img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + item.photo.data.attributes.url} alt={item.name} className="autoImg layout_image layout_image__round layout_mb__30"/>
                                        <h3 data-aos="fade-up" className='tt-normal'>{item.name}</h3>
                                        <p data-aos="fade-up" className="layout_text">{item.position}</p>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Team;