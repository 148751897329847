import { Col, Container, Row } from "react-bootstrap";
import HOME_QUERY from "../../queries/home";
import Query from "../Query";

const IntensivekursBlock = () => {
  let year = new Date().getFullYear();
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <section
            className="layout layout__bg layout__bg_primary"
            id="intensivkurs"
          >
            <Container fluid>
              <Row>
                <Col xs={12} xl={5} xxl={4} className="mb-5 mb-xl-0">
                  <Row className="flex-column justify-content-between h-100">
                    <Col className="mb-4 mb-xl-0">
                      <h2 data-aos="fade-up">
                        {home.data.attributes.Intensivecourse.title}
                      </h2>
                    </Col>
                    <Col xs={10} sm={10} md={8} lg={8} xl={7} xxl={9}>
                      <div className="layout_iframe" data-aos="fade-up">
                        <img
                          className="relative"
                          src={"/new/intensivkurs.webp"}
                          alt="intensivkurs"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  sm={11}
                  lg={8}
                  xl={6}
                  xxl={5}
                  className="offset-xxl-2"
                >
                  <p
                    className="layout_text"
                    data-aos="fade-up"
                    dangerouslySetInnerHTML={{
                      __html: home.data.attributes.Intensivecourse.description,
                    }}
                  ></p>
                </Col>
                <span className="layout_mt"></span>
                <Col xs={12} xl={5} xxl={4} className="mb-5 mb-xl-0">
                  <Row className="flex-xl-column align-items-end justify-content-between h-100">
                    <Col xs={7} sm={5} lg={4} xl={12}>
                      <h2 data-aos="fade-up">
                        {home.data.attributes.Intensivecourse.title2}
                      </h2>
                    </Col>
                    <Col className="d-xl-flex align-items-end">
                      <p
                        className="text-end text-xl-start layout_text layout_text__big layout_text__light"
                        data-aos="fade-up"
                      >
                        {year}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  md={11}
                  xl={7}
                  xxl={6}
                  className="offset-md-1 offset-xl-0 offset-xxl-2"
                >
                  <ul className="layout_list">
                    {home.data.attributes.Intensivecourse.Schedule.map(
                      (item) => {
                        return (
                          <li
                            className="layout_list_item w-100 row"
                            key={item.id}
                            data-aos="fade-up"
                          >
                            <Col
                              xs={4}
                              sm={3}
                              className="layout_list_marker d-flex align-items-center offset-xl-0"
                            >
                              {item.day}
                            </Col>
                            <Col xs={5} sm={6} className="text-start grow">
                              {item.date}
                            </Col>
                            <Col xs={3} sm={3} className="offset-xl-0 nowrap">
                              {item.time}
                            </Col>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>
        );
      }}
    </Query>
  );
};

export default IntensivekursBlock;
