import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Query from "../../components/Query";
import PUSH_QUERY from "../../queries/push";
import Anmelden from "../Content/anmelden";

let checker = true;

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      className="modal__special"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Query query={PUSH_QUERY} id={null}>
          {({ data: { push } }) => {
            let elem = push.data.attributes;
            return (
              <Row className="justify-content-center justify-content-xl-start align-items-center">
                <Col
                  xs={11}
                  md={10}
                  xl={6}
                  className="d-flex justify-content-center"
                >
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_URL +
                      elem.Thumb.data.attributes.url
                    }
                    alt={elem.title}
                    className="autoImg layout_image layout_image__round"
                  />
                </Col>
                <Col
                  xs={11}
                  md={10}
                  xl={6}
                  className="text-center text-xl-start"
                >
                  <h3
                    className="h3 layout_mb__30"
                    id="contained-modal-title-vcenter"
                  >
                    {elem.title}
                  </h3>
                  <div className="layout_text layout_mb__30">
                    <ReactMarkdown children={elem.description} />
                  </div>
                  <Anmelden
                    btn="btn btn__fill btn__fill_primary"
                    btnName={elem.buttontext}
                  ></Anmelden>
                </Col>
              </Row>
            );
          }}
        </Query>
      </Modal.Body>
    </Modal>
  );
}

function Push() {
  const [modalShowTwo, setModalTwoShow] = React.useState(false);
  return (
    <>
      <Query query={PUSH_QUERY} id={null}>
        {({ data: { push } }) => {
          let elem = push.data.attributes;
          if (elem.enabled) {
            window.addEventListener("scroll", () => {
              if (checker === true) {
                if (window.scrollY >= 1650) {
                  checker = false;
                  setModalTwoShow(true);
                }
              }
            });

            return (
              <MyVerticallyCenteredModal
                show={modalShowTwo}
                onHide={() => setModalTwoShow(false)}
              />
            );
          }
        }}
      </Query>
    </>
  );
}

export default Push;
