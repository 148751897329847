import React from "react";
import Modal from "react-bootstrap/Modal";
import UserForm from "../Form";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="md-down"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <UserForm hide={props.onHide} newform={props.newform}></UserForm>
      </Modal.Body>
    </Modal>
  );
}

function Anmelden({ btn, newform = true, btnName }) {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <button
        className={btn}
        variant="primary"
        onClick={() => setModalShow(true)}
      >
        {btnName ? btnName : "ANMELDEN"}
      </button>
      <MyVerticallyCenteredModal
        newform={newform}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Anmelden;
