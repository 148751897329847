import { Container } from "react-bootstrap";
import Header from "../../components/Header/category";
import TeamBlock from "../../components/Home/team";
import LeistungenBlock from "../../components/Home/leistungen";
import ReferalBlock from "../../components/Home/referal";
import AditionalBlock from "../../components/Content/aditional";
import OffersBlock from "../../components/Content/offer";
import PREISE_QUERY from "../../queries/preise";
import Query from "../../components/Query";
import FAQ from "../../components/Faq";

const Preise = () => {
  return (
    <Query query={PREISE_QUERY} id={null}>
      {({ data: { preise } }) => {
        const data = preise.data.attributes;
        return (
          <>
            <header className="layout layout__min layout__bg layout__bg_primary position-relative overflow-hidden">
              <Container fluid>
                <Header props={data.header} />
              </Container>
            </header>
            <main>
              <ReferalBlock
                title={data.mainpromo.title}
                desc={data.mainpromo.info}
                img={
                  process.env.REACT_APP_BACKEND_URL +
                  data.mainpromo.img.data.attributes.url
                }
              />
              {data.offers.length > 0 && <OffersBlock offers={data.offers} />}
              <AditionalBlock aditional={data.promo} />
              <section className="layout layout__color_primary">
                <Container fluid>
                  <div className="d-flex flex-column flex-sm-row w-100 justify-content-center gap-3 gap-md-4 gap-lg-5 mx-auto ">
                    <a
                      href="/pdf/preisliste_b.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn__big btn__border btn__border_primary"
                    >
                      Preisliste Klasse B
                    </a>
                    <a
                      href="/pdf/preisliste_be.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn__big btn__border btn__border_primary"
                    >
                      Preisliste Klasse BE
                    </a>
                  </div>
                </Container>
              </section>
              <LeistungenBlock />
              <TeamBlock />
              <section className="layout layout__color_primary">
                <Container fluid>
                  <h2 data-aos="fade-up" className="">
                    FAQ
                  </h2>
                  <h2 data-aos="fade-up" className="">
                    {data.faq.title}
                  </h2>
                  <FAQ elements={data.faq.qa}></FAQ>
                </Container>
              </section>
            </main>
          </>
        );
      }}
    </Query>
  );
};

export default Preise;
