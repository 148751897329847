import gql from "graphql-tag";

const KONTAKT_QUERY = gql`
  query Kontakt {
    contact {
      data {
        attributes {
          telephone
          mobile
          email
          address
          Facebook
          Instagram
          TikTok
          Map {
            data {
              attributes {
                url
              }
            }
          }
          helptime
        }
      }
    }
    # aboutUs {
    #   data {
    #     attributes {
    #       helptime
    #     }
    #   }
    # }
  }
`;

export default KONTAKT_QUERY;
