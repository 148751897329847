import { Container } from "react-bootstrap";
import Anmelden from "../Content/anmelden";
import { WrapText } from "../Utilites/wrapertext";

export default function HomeHeader({ data }) {
  const media = data?.media.data.attributes || {};

  return (
    <header className="header_v2 position-relative">
      {media &&
      (media.ext === ".webm" ||
        media.ext === ".mp4" ||
        media.ext === ".ogg" ||
        media.ext === ".ogv" ||
        media.ext === ".avi" ||
        media.ext === ".mov") ? (
        <video
          src={
            media.url
              ? process.env.REACT_APP_BACKEND_URL + media.url
              : "/header.webm"
          }
          // poster="/header.webp"
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          className="header_v2_video position-absolute"
        />
      ) : (
        <img
          src={
            media.url
              ? process.env.REACT_APP_BACKEND_URL + media.url
              : "/header.webp"
          }
          alt={media.alternativeText || "header image"}
          className="header_v2_img position-absolute"
        />
      )}
      <Container fluid className="h-100 position-relative">
        <div className="header_v2_mobile h-100 d-flex flex-column flex-lg-row align-items-center align-items-lg-end justify-content-end justify-content-lg-between position-relative z-3">
          <div>
            {data.price && data.price !== "0" && (
              <div>
                <h4
                  className="header_v2_mark"
                  data-aos="fade-right"
                  data-aos-offset="-100"
                >
                  {data.price}
                </h4>
              </div>
            )}
            <h1
              className="header_v2_title text-lg-start"
              data-aos="fade-right"
              data-aos-offset="-100"
            >
              <WrapText text={data.title} />
            </h1>
            <p
              className="header_v2_text text-lg-start"
              data-aos="fade-right"
              data-aos-offset="-100"
              dangerouslySetInnerHTML={{ __html: data?.info && data.info }}
            />
          </div>

          <div
            className="header_v2_btn"
            data-aos="fade-up"
            data-aos-offset="-100"
          >
            <Anmelden btn="btn btn__fill btn__fill_primary"></Anmelden>
          </div>
          <span className="header_v2_overlay d-lg-none" />
        </div>
      </Container>
      <span className="header_v2_overlay d-none d-lg-block" />
    </header>
  );
}
